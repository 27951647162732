import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import '@/global.css';
import { HeightCalc } from './components/HeightCalc';
import '@fontsource-variable/inter';
import { Providers } from '@/pages';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { SentryRouteErrorFallback } from './components/SentryRouteErrorFallback';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    Component: Providers,
    errorElement: <SentryRouteErrorFallback />,
    children: [
      {
        path: 'login',
        children: [
          {
            path: 'sign-in/:invitationId?',
            lazy: () => import('@/pages/login/sign-in'),
          },
          {
            path: 'sign-up/:invitationId?',
            lazy: () => import('@/pages/login/sign-up'),
          },
          {
            path: 'expired-invitation',
            lazy: () => import('@/pages/login/expired-invitation'),
          },
          {
            path: 'postlogin',
            lazy: () => import('@/pages/login/postlogin'),
          },
          {
            path: 'onboarding',
            lazy: () => import('@/pages/login/onboarding'),
          },
          {
            path: 'onboarding-account',
            lazy: () => import('@/pages/login/onboarding-account'),
          },
          {
            path: 'user',
            lazy: () => import('@/pages/login/user'),
          },
        ],
      },
      {
        path: 'w',
        children: [
          {
            path: 'sc',
            children: [
              {
                path: ':subcompanyId',
                lazy: () => import('@/pages/w/sc/(subcompanyId)'),
                children: [
                  {
                    path: 'dashboard',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/dashboard'),
                  },
                  {
                    path: 'inbox',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/inbox'),
                  },
                  {
                    path: 'conversation/:conversationType/:conversationId?',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/(conversationType)'),
                  },
                  {
                    path: 'chatbots/:chatbotId?/*',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId?/intents/:stateId',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/intents/(stateId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/webchat/:channelId',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/webchat/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/africas_talking/:channelId',
                    lazy: () =>
                      import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/africas-talking/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/messenger/:channelId',
                    lazy: () =>
                      import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/messenger/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/whatsapp/:channelId',
                    lazy: () =>
                      import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/whatsapp/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/telegram/:channelId',
                    lazy: () =>
                      import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/telegram/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/line/:channelId',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/line/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/bitrix24/:channelId',
                    lazy: () =>
                      import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/bitrix24/(channelId)'),
                  },
                  {
                    path: 'chatbots/:chatbotId/channels/zendesk',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/chatbots/(chatbotId)/channels/zendesk'),
                  },
                  {
                    path: 'customers/:customerId?',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/customers'),
                  },
                  {
                    path: 'analytics',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/analytics'),
                  },
                  {
                    path: 'settings',
                    lazy: () => import('@/pages/w/sc/(subcompanyId)/settings'),
                    children: [
                      {
                        path: 'workspace',
                        children: [
                          {
                            path: 'general',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/general'),
                          },
                          {
                            path: 'subcompanies',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/subcompanies'),
                          },
                          {
                            path: 'plan-and-billing',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/plan-and-billing'),
                          },
                          {
                            path: 'roles-and-permissions',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/roles-and-permissions'),
                          },
                          {
                            path: 'users',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/users'),
                          },
                          {
                            path: 'teams',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/teams'),
                          },
                          {
                            path: 'ip-allowlist',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/ip-allowlist'),
                          },
                          {
                            path: 'whitelabelling',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/workspace/whitelabelling'),
                          },
                        ],
                      },
                      {
                        path: 'subcompany',
                        children: [
                          {
                            path: 'banning',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/banning'),
                          },
                          {
                            path: 'tags',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/tags'),
                          },
                          {
                            path: 'assignment',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/assignment'),
                          },
                          {
                            path: 'email-domains',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/email-domains'),
                          },
                          {
                            path: 'email-domains/:emailDomainId',
                            lazy: () =>
                              import('@/pages/w/sc/(subcompanyId)/settings/subcompany/email-domains/(emailDomainId)'),
                          },
                          {
                            path: 'email-templates',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/email-templates'),
                          },
                          {
                            path: 'email-templates/:emailTemplateId',
                            lazy: () =>
                              import(
                                '@/pages/w/sc/(subcompanyId)/settings/subcompany/email-templates/(emailTemplateId)'
                              ),
                          },
                          {
                            path: 'livechat',
                            children: [
                              {
                                path: 'canned-replies',
                                lazy: () =>
                                  import('@/pages/w/sc/(subcompanyId)/settings/subcompany/livechat/canned-replies'),
                              },
                              {
                                path: 'custom-fields',
                                lazy: () =>
                                  import('@/pages/w/sc/(subcompanyId)/settings/subcompany/livechat/custom-fields'),
                              },
                            ],
                          },
                          {
                            path: 'tickets',
                            children: [
                              {
                                path: 'emails',
                                lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/tickets/emails'),
                              },
                              {
                                path: 'custom-fields',
                                lazy: () =>
                                  import('@/pages/w/sc/(subcompanyId)/settings/subcompany/tickets/custom-fields'),
                              },
                            ],
                          },
                          {
                            path: 'exports',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/subcompany/exports'),
                          },
                          {
                            path: 'customers',
                            children: [
                              {
                                path: 'profile-iframe',
                                lazy: () =>
                                  import('@/pages/w/sc/(subcompanyId)/settings/subcompany/customers/profile-iframe'),
                              },
                              {
                                path: 'custom-fields',
                                lazy: () =>
                                  import('@/pages/w/sc/(subcompanyId)/settings/subcompany/customers/custom-fields'),
                              },
                            ],
                          },
                          {
                            path: 'analytics',
                            children: [
                              {
                                path: 'winnow-iframe',
                                lazy: () =>
                                  import('@/pages/w/sc/(subcompanyId)/settings/subcompany/analytics/winnow-iframe'),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'addons',
                        children: [
                          {
                            path: 'proanalytics',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/addons/proanalytics'),
                          },
                        ],
                      },
                      {
                        path: 'profile',
                        children: [
                          {
                            path: 'account',
                            lazy: () => import('@/pages/w/sc/(subcompanyId)/settings/profile/account'),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'ip-allowlist-unauthorized',
    lazy: () => import('@/pages/ip-allowlist-unauthorized'),
  },
]);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: !!import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/(.*)proto\.cx\/api/, /^https:\/\/(.*)yola\.ai\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
    <HeightCalc />
  </React.StrictMode>
);
